<template>
  <div>
    <PageHeader :title="pageTitle" :items="breadcrumb" />
    <!-- Filtro -->
    <b-card class="mb-3 mt-3">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h4 class="text-center mb-3">Filtrar</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="mb-3">
            <b-form-group label="Ano" label-for="filtroAno">
              <b-form-input id="filtroAno" type="number" v-model="filtroAno" />
            </b-form-group>
          </b-col>

          <b-col md="3" class="mb-3">
            <b-form-group label="Mês" label-for="filtroMes">
              <b-form-select
                id="filtroMes"
                v-model="filtroMes"
                :options="mesesOptions"
                placeholder="Selecione o mês"
              />
            </b-form-group>
          </b-col>

          <b-col md="3" class="mb-3">
            <b-form-group label="Tipo" label-for="filtroTipo">
              <b-form-select
                id="filtroTipo"
                v-model="filtroTipo"
                :options="tipos"
                placeholder="Selecione o tipo"
              />
            </b-form-group>
          </b-col>

          <b-col md="3" class="mb-3">
            <b-form-group label="UF" label-for="filtroUF">
              <b-form-select
                id="filtroUF"
                v-model="filtroUF"
                :options="estadosOptions"
                placeholder="Selecione o estado"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="d-flex justify-content">
            <b-button @click="redirectPage(feriados)" variant="success"
              >Cadastrar Feriado</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- Tabela de Feriados -->
    <b-card>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h4 class="titulo mb-3">Feriados</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-table
              :items="feriadosFiltrados"
              :fields="camposTabela"
              striped
              hover
            >
              <template v-slot:cell(id)="row">
                <router-link
                  :to="{
                    path: '/edit-feriados',
                    query: { data: JSON.stringify(row.item) }
                  }"
                  class="underline-hover"
                >
                  {{ row.value }}
                </router-link>
              </template>
              <template v-slot:cell(data)="row">
                <span>{{ formatarFeriado(row.value) }}</span>
              </template>
              <template v-slot:cell(tipo_feriado)="row">
                {{ obterNomeTipo(row.value) }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header';
import { listAll, formatarData } from './feriado.service';
import estados from '@/utils/estados';
import meses from '@/utils/meses';

export default {
  components: {
    PageHeader
  },
  data() {
    return {
      pageTitle: 'Feriados',
      breadcrumb: [
        { text: 'Dias Úteis', href: '/' },
        { text: 'Feriados', active: true }
      ],
      filtroAno: new Date().getFullYear(),
      filtroMes: null,
      filtroTipo: null,
      filtroUF: '',
      mesesOptions: [{ text: 'Todos', value: null }, ...meses()],
      tipos: [
        { text: 'Todos', value: null },
        { text: 'Nacional', value: 'N' },
        { text: 'Estadual', value: 'E' },
        { text: 'Municipal', value: 'M' }
      ],
      camposTabela: [
        { key: 'id', label: 'ID' },
        { key: 'data', label: 'Data' },
        { key: 'descricao', label: 'Descrição' },
        { key: 'tipo_feriado', label: 'Tipo' },
        { key: 'municipio', label: 'Município' },
        { key: 'uf', label: 'Estado' }
      ],
      feriados: [],
      estadosOptions: [{ text: 'Todos', value: '' }, ...estados()]
    };
  },
  computed: {
    feriadosFiltrados() {
      return this.feriados.filter((feriado) => {
        const filtroAno = this.filtroAno
          ? new Date(feriado.data).getFullYear() === parseInt(this.filtroAno)
          : true;
        const filtroMes =
          this.filtroMes === null ||
          new Date(feriado.data).getMonth() + 1 === parseInt(this.filtroMes) ||
          this.filtroMes === 'Todos';
        const filtroTipo =
          this.filtroTipo === null ||
          this.filtroTipo === 'Todos' ||
          feriado.tipo_feriado === this.filtroTipo;
        const filtroUF = this.filtroUF === '' || feriado.uf === this.filtroUF;

        return filtroAno && filtroMes && filtroTipo && filtroUF;
      });
    }
  },
  methods: {
    obterNomeTipo(tipo) {
      switch (tipo) {
        case 'N':
          return 'Nacional';
        case 'E':
          return 'Estadual';
        case 'M':
          return 'Municipal';
        default:
          return '';
      }
    },
    redirectPage(feriados) {
      this.$router.push({
        path: '/cadastro-feriados',
        query: { data: JSON.stringify(feriados) }
      });
    },
    formatarFeriado(data) {
      return formatarData(data);
    },
    buscarFeriados() {
      listAll().then((response) => {
        if (response.status === true) {
          this.feriados = response.content;
        }
      });
    }
  },
  mounted() {
    this.buscarFeriados();
  }
};
</script>

<style>
.filtros {
  display: flex;
}

.form-group {
  flex-grow: 1;
  padding: 15px;
}

.titulo {
  text-align: center;
}

.btn.btn-success {
  width: 15%;
  margin: 5px;
}

.underline-hover:hover {
  text-decoration: underline !important;
}
</style>
